import chunk from 'lodash/chunk';
import shuffle from 'lodash/shuffle';
import { useEffect } from 'react';
import { match } from 'ts-pattern';
import { proxy, useSnapshot } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsJeopardyTurnResult,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import {
  BlockType,
  type JeopardyBlock,
  type MultipleChoiceBlock,
  type QuestionBlock,
} from '@lp-lib/game/src/block';
import { type Logger } from '@lp-lib/logger-base';
import { MediaFormatVersion } from '@lp-lib/media';

import { apiService } from '../../../../services/api-service';
import { type BaseUser } from '../../../../types';
import { getStaticAssetPath } from '../../../../utils/assets';
import { xDomainifyUrl } from '../../../../utils/common';
import { MediaUtils } from '../../../../utils/media';
import {
  HTMLAudioPool,
  UnplayableAudioImpl,
  UnplayableVideoImpl,
} from '../../../../utils/unplayable';
import {
  markSnapshottable,
  type ValtioSnapshottable,
} from '../../../../utils/valtio';
import { type SoundEffectKeys } from '../../../SFX';
import { JeopardyControl } from '../../apis/JeopardyControl';
import { useOrgMasqueradeFallback } from '../../apis/OrgMasqueradeFallback';
import { BlockContainer } from '../../design/BlockContainer';
import { CommonButton } from '../../design/Button';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { MultipleChoiceBlockControlAPI } from '../MultipleChoice/MultipleChoiceBlock';
import { QuestionBlockControlAPI } from '../Question/QuestionBlock';
import { JeopardyBoard } from './JeopardyBoard';
import {
  JeopardyCluePlayground,
  JeopardyWagerPoints,
} from './JeopardyCluePlayground';
import { JeopardyPlayerTracker } from './JeopardyPlayerTracker';
import { JeopardyTurnTracker } from './JeopardyTurnTracker';
import { JeopardyGamePrompt } from './Notifications';
import { getOutputSchema, type JeopardyBlockOutputSchema } from './outputs';
import { JeopardyBackground } from './Shared';
import {
  JEOPARDY_BOARD_FILL_DURATION_SEC,
  JEOPARDY_SKIP_CATEGORIES_PRESENTATION,
  JEOPARDY_SKIP_INTRO,
  JEOPARDY_TURNS_COUNT,
  type JeopardyGameCategory,
  type JeopardyGameClue,
  type JeopardyGameState,
  type JeopardyPlayer,
  type JeopardyStage,
  type JeopardyStageIntro,
  type JeopardyStagePlayClue,
  type JeopardyTurn,
} from './types';
import { JeopardyUtils } from './utils';

const introAudio = xDomainifyUrl(
  getStaticAssetPath('audios/jeopardy/intro-2025-03-31.mp3')
);
const introVideo = xDomainifyUrl(
  getStaticAssetPath('videos/jeopardy/intro-2025-03-31.mp4')
);

type JeopardyResolvedTTS = {
  welcome: Nullable<DtoTTSRenderRequest>;
  categoriesIntro: Nullable<DtoTTSRenderRequest>;
  categories: Record<string, Nullable<DtoTTSRenderRequest>>;
  selectClue: Nullable<DtoTTSRenderRequest>;
  dailyDouble: Nullable<DtoTTSRenderRequest>;
};

export class JeopardyBlockControlAPI implements IBlockCtrl {
  private _state: ValtioSnapshottable<JeopardyGameState>;
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private _logger: Logger;
  private resolvedTTS: JeopardyResolvedTTS = {
    welcome: null,
    categoriesIntro: null,
    categories: {},
    selectClue: null,
    dailyDouble: null,
  };
  private _jeopardyControl: JeopardyControl;
  private user: BaseUser;
  private wagerIndex = 0;
  private aborter: AbortController;
  private schema: JeopardyBlockOutputSchema;

  constructor(private block: JeopardyBlock, private deps: BlockDependencies) {
    this._logger = deps.getLogger('jeopardy-block');
    this.user = deps.getUser() ?? {
      id: '0',
      username: 'Guest',
    };

    this._state = markSnapshottable(
      proxy(JeopardyUtils.MakeInitialGameState(block, this.user))
    );
    this._jeopardyControl = new JeopardyControl();
    this.aborter = new AbortController();
    this.wagerIndex = Math.floor(Math.random() * JEOPARDY_TURNS_COUNT);
    this.schema = getOutputSchema(block);
  }

  get logger(): Logger {
    return this._logger;
  }

  get state(): JeopardyGameState {
    return this._state;
  }

  get jeopardyControl() {
    return this._jeopardyControl;
  }

  async preload() {
    // this.preloadCompetitors();
    this.preloadVoiceOvers();

    const tasks = [];
    const audio = new UnplayableAudioImpl(introAudio);
    tasks.push(audio.intoPlayable().then(() => void 0));

    const video = new UnplayableVideoImpl(introVideo);
    tasks.push(video.intoPlayable().then(() => void 0));

    await Promise.all(tasks);
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
    this.deps.tutorControl.setFeatureEnabled(false);
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async preloadCompetitors() {
    const resp = await apiService.block.queryJeopardyCompetitions(
      this.block.id
    );

    const players: JeopardyPlayer[] = [JeopardyUtils.NewMyPlayer(this.user)];
    const sessions = resp.data.sessions.slice(0, 2);
    for (let i = 0; i < sessions.length; i++) {
      const player = JeopardyUtils.NewPlayerFromSession(
        sessions[i],
        players.length
      );
      players.push(player);
    }
    const remaining = 3 - players.length;
    for (let i = 0; i < remaining; i++) {
      players.push(
        JeopardyUtils.NewMockedPlayer(players.length, this.state.board)
      );
    }
    this.state.players = players;
  }

  async present() {
    if (JEOPARDY_SKIP_INTRO) {
      await this.initBoard();
      return;
    }

    this.state.stage = { state: 'intro', showWelcome: false };

    // play intro video
    await this.sleep(7000);
    // show board name & play welcome voice over
    this.state.stage.showWelcome = true;
    await this.playVoiceOver(this.resolvedTTS.welcome);
    await this.sleep(1000);

    await this.initBoard();
  }

  async initBoard() {
    this._state.stage = { state: 'init-board' };
    await this.presentBoard();
  }

  async presentBoard() {
    if (this.state.stage.state !== 'init-board') return;

    // animate the board in
    this.state.stage.animate = 'slide-in';
    await this.sleep(500);

    // fill the board
    this.state.stage.animate = 'filling';
    this.playSoundEffect('jeopardyBoardFill');
    const cells: (JeopardyGameCategory | JeopardyGameClue)[] = [];
    for (const category of this._state.board.categories) {
      cells.push(category);
      for (const clue of category.clues) {
        cells.push(clue);
      }
    }
    const shuffled = shuffle(cells);
    const steps = 2 * JEOPARDY_BOARD_FILL_DURATION_SEC;
    const idealChunkSize = Math.ceil(shuffled.length / steps);
    const chunked = chunk(shuffled, idealChunkSize);
    const delay = (JEOPARDY_BOARD_FILL_DURATION_SEC * 1000) / chunked.length;
    for (const chunk of chunked) {
      for (const cell of chunk) {
        cell.status = 'present';
      }
      await this.sleep(delay);
    }

    this.state.stage.animate = 'done';

    if (!JEOPARDY_SKIP_CATEGORIES_PRESENTATION) {
      await this.presentCategories();
    }

    this.waitingClueSelection();
  }

  async presentCategories() {
    this._state.stage = { state: 'present-categories', index: -1 };

    await this.playVoiceOver(this.resolvedTTS.categoriesIntro);
    await this.sleep(1000);

    for (let i = 0; i < this._state.board.categories.length; i++) {
      const category = this._state.board.categories[i];
      this._state.stage = {
        state: 'present-categories',
        index: i,
        category,
      };
      await this.sleep(400);
      await this.playVoiceOver(this.resolvedTTS.categories[category.id]);
      await this.sleep(1000);
    }
  }

  async waitingClueSelection() {
    this._state.stage = { state: 'waiting-clue-selection' };
    await this.playVoiceOver(this.resolvedTTS.selectClue);
    for (const category of this._state.board.categories) {
      for (const clue of category.clues) {
        if (clue.status === 'present') {
          clue.status = 'selectable';
        }
      }
    }
  }

  async selectClue(clue: JeopardyGameClue) {
    this.playSoundEffect('jeopardyDing');

    const isWagerTurn = this.wagerIndex === this._state.playedTurns.length;

    this._state.stage = { state: 'play-clue', clue, isWagerTurn };
    for (const category of this._state.board.categories) {
      for (const c of category.clues) {
        if (c.status === 'selectable') {
          c.status = 'present';
        }
      }
    }

    this._state.stage.animateClueCellBlinking = true;
    await this.sleep(600);
    this._state.stage.animateClueCellBlinking = false;

    this._state.stage.animateClueCellScaleUp = true;
    await this.sleep(450);
    this._state.stage.animateClueCellScaleUp = false;

    if (isWagerTurn) {
      this._state.stage.animateDailyDouble = true;
      // slide "daily double" text in
      await this.sleep(300);
      // play sound effect
      this.playSoundEffect('dailyDouble');
      await this.sleep(3000);

      this._state.stage.animateDailyDouble = false;

      // show wager points
      this._state.stage.showWager = true;
      this.playVoiceOver(this.resolvedTTS.dailyDouble);
    } else {
      this._state.stage.showCluePlayground = true;
    }
  }

  endWager() {
    if (this._state.stage.state !== 'play-clue') return;

    this._state.stage.showWager = false;
    this._state.stage.showCluePlayground = true;
  }

  async completeClue(turn: JeopardyTurn) {
    for (const category of this._state.board.categories) {
      for (const c of category.clues) {
        if (c.id === turn.clue.id) {
          c.status = 'played';
        }
      }
    }
    this._state.totalPoints += this._jeopardyControl.state.points;
    const points = match(turn.result)
      .with(
        EnumsJeopardyTurnResult.JeopardyTurnResultCorrect,
        () => this._jeopardyControl.state.points
      )
      .with(
        EnumsJeopardyTurnResult.JeopardyTurnResultIncorrect,
        () => -this._jeopardyControl.state.points
      )
      .with(EnumsJeopardyTurnResult.JeopardyTurnResultSkipped, () => 0)
      .exhaustive();
    for (const player of this._state.players) {
      if (player.isMe) {
        player.score += points;
        player.history.push({
          clueId: turn.clue.id,
          points,
          result: turn.result,
          total: player.score,
        });
      } else if (player.history.length > this._state.playedTurns.length) {
        player.score = player.history[this._state.playedTurns.length].total;
      }
    }
    this._state.players.sort((a, b) => b.score - a.score);
    this._state.players.forEach((player, index) => {
      player.order = index;
    });

    this._state.playedTurns.push(turn);

    if (this._state.playedTurns.length >= JEOPARDY_TURNS_COUNT) {
      this.showResult();
      return;
    }

    if (this._state.stage.state === 'play-clue') {
      this._state.stage.showCluePlayground = false;
    }
    this.playSoundEffect('jeopardyProgress');
    await this.sleep(300);
    this.waitingClueSelection();
  }

  async showResult() {
    this.trackBlockOutput();
    this._state.stage = { state: 'result' };

    const me = this.state.players.find((p) => p.isMe);
    if (me) {
      apiService.block.createJeopardySession(this.block.id, {
        data: {
          score: me.score,
          history: me.history,
        },
      });
    }
  }

  private trackBlockOutput() {
    let correctCount = 0;
    let incorrectCount = 0;
    const correctCategories = new Set<string>();
    const incorrectCategories = new Set<string>();
    for (const turn of this._state.playedTurns) {
      const category = this._state.board.categories.find((c) =>
        c.clues.some((c) => c.id === turn.clue.id)
      );
      if (turn.result === 'correct') {
        correctCount++;
        if (category) {
          correctCategories.add(category.name);
        }
      } else {
        incorrectCount++;
        if (category) {
          incorrectCategories.add(category.name);
        }
      }
    }
    this.delegate?.blockDidOutput(this.schema.correctCount, correctCount);
    this.delegate?.blockDidOutput(this.schema.incorrectCount, incorrectCount);
    this.delegate?.blockDidOutput(
      this.schema.correctCategories,
      Array.from(correctCategories).join(',')
    );
    this.delegate?.blockDidOutput(
      this.schema.incorrectCategories,
      Array.from(incorrectCategories).join(',')
    );
    const me = this._state.players.find((p) => p.isMe);
    this.delegate?.blockDidOutput(this.schema.points, me?.score || 0);
    this.delegate?.blockDidOutput(
      this.schema.totalPoints,
      this._state.totalPoints
    );
  }

  async end() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.delegate?.blockDidEnd();
  }

  private async sleep(ms: number) {
    await new Promise((resolve, reject) => {
      const timeoutId = setTimeout(resolve, ms);

      this.aborter.signal?.addEventListener(
        'abort',
        () => {
          clearTimeout(timeoutId);
          reject(new Error('Sleep aborted'));
        },
        { once: true }
      );
    });
  }

  async mockMultipleChoiceBlock(clue: JeopardyGameClue) {
    const category = this._state.board.categories.find((c) =>
      c.clues.some((c) => c.id === clue.id)
    );
    if (!category) return;

    const block: MultipleChoiceBlock = {
      type: BlockType.MULTIPLE_CHOICE,
      id: '',
      position: 0,
      gameId: '',
      createdAt: '',
      updatedAt: '',
      outdatedRecording: false,
      approximateDurationSeconds: 0,
      fields: {
        title: '',
        internalLabel: '',
        question: clue.question,
        answerChoices: clue.answerChoices,
        questionTimeSec: 20,
        points: clue.points,
        displayPointsMultiplier: false,
        decreasingPointsTimer: false,
        startVideoWithTimer: false,
        personalityId: this.block.fields.personalityId,
        questionMedia: null,
        backgroundMedia: null,
        answerMedia: null,
        questionMediaData: null,
        backgroundMediaId: null,
        answerMediaData: null,
      },
    };
    this._jeopardyControl.setClue({
      category: category.name,
      points: clue.points,
    });
    this._jeopardyControl.once('end', (result) => {
      this.completeClue({
        clue,
        result,
      });
    });

    const multipleChoiceBlockCtrl = new MultipleChoiceBlockControlAPI(block, {
      ...this.deps,
      jeopardyControl: this._jeopardyControl,
    });
    await multipleChoiceBlockCtrl.initialize(multipleChoiceBlockCtrl.preload());

    return {
      multipleChoiceBlock: block,
      multipleChoiceBlockCtrl,
    };
  }

  async mockQuestionBlock(clue: JeopardyGameClue) {
    const category = this._state.board.categories.find((c) =>
      c.clues.some((c) => c.id === clue.id)
    );
    if (!category) return;

    const block: QuestionBlock = {
      type: BlockType.QUESTION,
      id: '',
      position: 0,
      gameId: '',
      createdAt: '',
      updatedAt: '',
      outdatedRecording: false,
      approximateDurationSeconds: 0,
      fields: {
        title: '',
        internalLabel: '',
        question: clue.question,
        answer: clue.answer,
        points: clue.points,
        additionalAnswers: '',
        time: 20,
        displayPointsMultiplier: false,
        scoreboard: false,
        decreasingPointsTimer: false,
        startVideoWithTimer: false,
        startDescendingImmediately: false,
        questionMedia: null,
        answerMedia: null,
        questionMediaData: null,
        answerMediaData: null,
        personalityId: this.block.fields.personalityId,
      },
    };

    this._jeopardyControl.setClue({
      category: category.name,
      points: clue.points,
    });
    this._jeopardyControl.once('end', (result) => {
      this.completeClue({
        clue,
        result,
      });
    });

    const questionBlockCtrl = new QuestionBlockControlAPI(block, {
      ...this.deps,
      jeopardyControl: this._jeopardyControl,
    });
    await questionBlockCtrl.initialize(questionBlockCtrl.preload());

    return {
      questionBlock: block,
      questionBlockCtrl,
    };
  }

  playSoundEffect(key: SoundEffectKeys) {
    return this.deps.sfxControl.play(key);
  }

  async destroy() {
    this.aborter.abort();
  }

  private async preloadVoiceOvers() {
    this.resolvedTTS.welcome = await this.preloadVoiceOverRequest(
      `Welcome to Jeopardy, ${this.block.fields.board.name} edition!`
    );
    this.resolvedTTS.categoriesIntro = await this.preloadVoiceOverRequest(
      `Let's take a look at the categories.`
    );
    for (const category of this.state.board.categories) {
      this.resolvedTTS.categories[category.id] =
        await this.preloadVoiceOverRequest(category.name);
    }
    this.resolvedTTS.selectClue = await this.preloadVoiceOverRequest(
      `Please select a question.`
    );
    this.resolvedTTS.dailyDouble = await this.preloadVoiceOverRequest(
      `You've found the Daily Double! Get ready to wager your points.`
    );
  }

  private async preloadVoiceOverRequest(script: string) {
    const req = await this.makeTTSRenderRequest(script);
    if (!req) return null;
    await this.deps.lvoLocalCacheWarm(req);
    return req;
  }

  private async playVoiceOver(req: Nullable<DtoTTSRenderRequest>) {
    if (!req) return;

    try {
      const player = this.deps.createLVOLocalPlayer(req);
      const info = await player.playFromPool();
      await info?.trackEnded;
    } catch (e) {
      this.logger.error(`failed to play Voice Over`, e);
    }
  }

  private async makeTTSRenderRequest(
    script: string
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }
}

function CategoriesPresentation(props: {
  categories: JeopardyGameCategory[];
  index: number;
}) {
  const { categories, index } = props;

  return (
    <div
      className='w-full h-full flex items-center transform transition-transform'
      style={{
        transform: `translateX(${index * -100}%)`,
        transitionDuration: '0.5s',
      }}
    >
      {categories.map((c) => (
        <div
          key={c.id}
          className={`
                  w-full h-full flex-none bg-[#0029FF]
                  p-4 flex items-center justify-center
                  text-center text-white text-3.5xl font-bold
                `}
        >
          {c.name}
        </div>
      ))}
    </div>
  );
}

function Result(props: { ctrl: JeopardyBlockControlAPI }) {
  const { players } = useSnapshot(props.ctrl.state);

  return (
    <>
      <div className='fixed inset-0 opacity-20'>
        <video
          src={getStaticAssetPath('videos/jeopardy/outro-2025-03-31.mp4')}
          className='w-full h-full object-cover animate-fade-in'
          autoPlay
          playsInline
        />
      </div>

      <div className='relative w-full h-full flex flex-col justify-between items-center text-white py-7.5'>
        <div className='flex-1 flex flex-col items-center justify-center gap-6'>
          <div className='text-xl xl:text-2xl font-bold'>Your Final Score</div>
          {players.map((player) => (
            <div
              key={player.uid}
              className={`flex items-center gap-3 ${
                player.order === 0 ? '' : 'ml-2.5'
              }`}
            >
              {/*<div*/}
              {/*  className={`*/}
              {/*    font-bold italic*/}
              {/*    ${player.order === 0 ? 'text-2xl' : 'text-base'}*/}
              {/*  `}*/}
              {/*>*/}
              {/*  {player.order === 0*/}
              {/*    ? '1st'*/}
              {/*    : player.order === 1*/}
              {/*    ? '2nd'*/}
              {/*    : '3rd'}*/}
              {/*</div>*/}
              <div key={player.uid} className='flex items-center gap-1'>
                <div
                  className={`flex-none bg-[#D9D9D9] rounded-xl ${
                    player.order === 0 ? 'w-10 h-10' : 'w-8.5 h-8.5'
                  }`}
                >
                  <video
                    src={player.icon}
                    className='w-full h-full object-cover rounded-xl'
                    autoPlay
                    muted
                    loop
                    playsInline
                  />
                </div>
                <div className='flex-1 overflow-hidden flex flex-col gap-0.5'>
                  <p className='text-3xs truncate text-white'>
                    {player.username}
                    {player.isMe ? ' (You)' : ''}
                  </p>
                  <p
                    className={`
                    text-tertiary font-bold
                    ${player.order === 0 ? 'text-lg' : 'text-sms'}
                  `}
                  >
                    {player.score < 0 ? '-' : ''}${Math.abs(player.score)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <CommonButton variant={'correct'} onClick={() => props.ctrl.end()}>
          Continue
        </CommonButton>
      </div>
    </>
  );
}

function Init(props: { block: JeopardyBlock; ctrl: JeopardyBlockControlAPI }) {
  useEffect(() => {
    try {
      props.ctrl.present();
    } catch (e) {
      console.error('failed to present', e);
    }
  }, [props.ctrl]);

  return null;
}

function Intro(props: {
  stage: JeopardyStageIntro;
  block: JeopardyBlock;
  ctrl: JeopardyBlockControlAPI;
}) {
  const { stage, block } = props;

  const organization = useOrgMasqueradeFallback();

  const logoSrc = MediaUtils.PickMediaUrl(organization?.logo, {
    priority: [MediaFormatVersion.SM],
  });

  useEffect(() => {
    const audioElement = HTMLAudioPool.GetInstance().retain();
    if (audioElement) {
      audioElement.src = introAudio;
      audioElement.play().catch((e) => {
        props.ctrl.logger.error('failed to play audio', e);
      });
    }
    return () => {
      if (audioElement) {
        audioElement.pause();
        HTMLAudioPool.GetInstance().release(audioElement);
      }
    };
  }, [props.ctrl.logger]);

  return (
    <div className='fixed inset-0'>
      <video
        src={introVideo}
        className='w-full h-full object-cover'
        playsInline
        autoPlay
        muted
      />

      {(logoSrc || block.fields.board.name) && (
        <div
          className={`
          absolute inset-0 p-5 xl:p-10 bg-lp-black-004
          ${stage.showWelcome ? 'opacity-100' : 'opacity-0'}
          transition-opacity duration-500
          flex justify-center items-center gap-6
        `}
        >
          {logoSrc && (
            <div className='flex-none w-20 h-20'>
              <img
                src={logoSrc}
                alt=''
                className='w-full h-full object-contain'
              />
            </div>
          )}
          <p className='text-3.5xl font-bold italic text-white text-center'>
            {block.fields.board.name}
          </p>
        </div>
      )}
    </div>
  );
}

function Playing(props: {
  state: JeopardyGameState;
  stage: JeopardyStage;
  block: JeopardyBlock;
  ctrl: JeopardyBlockControlAPI;
}) {
  const { state, stage, block, ctrl } = props;

  return (
    <>
      <div className='fixed inset-0'>
        <JeopardyBackground />
      </div>

      <BlockContainer className='py-3 flex flex-col items-center'>
        {stage.state === 'waiting-clue-selection' &&
        state.playedTurns.length === 0 ? (
          <div className='absolute top-3 animate-fade-in z-10'>
            <JeopardyGamePrompt />
          </div>
        ) : null}

        <JeopardyPlayerTracker block={block} ctrl={ctrl} animatePopIn={true} />

        <div className='mt-7 w-full flex justify-center items-center'>
          <JeopardyTurnTracker block={props.block} ctrl={props.ctrl} />
        </div>

        <div
          className={`
            mt-2 w-full flex-1 overflow-hidden bg-black p-2.5
          `}
        >
          <div className='relative w-full h-full'>
            <JeopardyBoard
              block={props.block}
              ctrl={props.ctrl}
              stage={stage as JeopardyStage}
            />

            {stage.state === 'present-categories' ? (
              <div className='absolute inset-0 overflow-hidden'>
                <CategoriesPresentation
                  categories={state.board.categories as JeopardyGameCategory[]}
                  index={stage.index}
                />
              </div>
            ) : null}

            {stage.state === 'play-clue' && stage.showWager ? (
              <div className='absolute inset-0'>
                <JeopardyWagerPoints ctrl={props.ctrl} />
              </div>
            ) : null}
          </div>
        </div>

        {stage.state === 'play-clue' && (
          <JeopardyCluePlayground
            stage={stage as JeopardyStagePlayClue}
            ctrl={props.ctrl}
          />
        )}
      </BlockContainer>
    </>
  );
}

export function JeopardyBlockPlayground(props: {
  block: JeopardyBlock;
  ctrl: JeopardyBlockControlAPI;
}) {
  const state = useSnapshot(props.ctrl.state);
  const stage = state.stage;

  switch (stage.state) {
    case 'init':
      return <Init block={props.block} ctrl={props.ctrl} />;
    case 'intro':
      return <Intro stage={stage} block={props.block} ctrl={props.ctrl} />;
    case 'init-board':
    case 'present-categories':
    case 'waiting-clue-selection':
    case 'play-clue':
      return (
        <Playing
          state={state as JeopardyGameState}
          stage={stage as JeopardyStage}
          block={props.block}
          ctrl={props.ctrl}
        />
      );
    case 'result':
      return <Result ctrl={props.ctrl} />;
  }
}
